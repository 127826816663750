/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
import '../styles/Cards.css'
// import ac from '../assets/ambiente/acustica-ambientale.jpg'
import bg from '../assets/bg.jpg'
import Fuse from 'fuse.js';
import prova from '../pages/blogPages/prova'

const blog = () => {
    const [ca] = useState([
        {
          image: {bg},
          title: 'Numero Clienti Serviti ',
          text:'1.400+',
          link: 'prova'
        },
        {
          title: 'Istituzioni Pubbliche seguite',
          text:'60+',   
        },
        {
          title: 'Visite Mediche effettuate',
          text:'10.000+',
        },
        {
          title: 'Controlli effettuati dalla ANSL',
          text:'5.000+',     
        },
        {
          title: 'Anni di Esperienza',
          text:'20+',     
        },
        {
          title: 'Servizi All Inclusive',
          text:'360°',      
        },
        {
          title: 'Innovazione ',
          text:'100%',      
        },
        {
          title: 'Lavoratori Formati',
          text:'20.000+',  
        },

      ])
      const [searchTerm, setSearchTerm] = useState("");
      const [searchResults, setSearchResults] = useState([]);
      const handleSearch = (event) => {
        setSearchTerm(event.target.value);
      };
      const fuse = new Fuse(ca, {
        keys: ["title", "text"],
        threshold: 0.3,
      });
      useEffect(() => {
        const results = fuse.search(searchTerm);
        setSearchResults(results);
      }, [searchTerm, ca]);
      return (
        <>
        <div >
          <section className='section bg-white'>
            <div className='container1'><br></br><br></br><br></br><br></br>
              <h1 id='tit'> In Costruzione...</h1>
              {/* <h1 id='tit'> ANSL Blog</h1> */}
              {/* <input type="text" placeholder="Cerca..." onChange={handleSearch}  />
              <div className='cards2'>
              
                {searchTerm.length === 0
                ? ca.map((item) => (
                    <div className='card1' key={item.id}><br/><br/>
                        <h2 id='tecard'>{item.title}</h2>
                        <p id='pcol'>{item.text}</p>
                        <a href={item.link}>Clicca qui!</a>
                    </div>
                    ))
              :searchResults.map((item) => (
                <div className='card1' key={item.id}>
                    <h2 id='tecard'>{item.title}</h2>
                    <p id='pcol'>{item.text}</p>
                    <a href={item.link}>Clicca qui!</a>
                </div>
                ))}
              </div> */}
            </div>
          </section>
        </div>
        </>
    // <>
    //     <div className=' section'><br/><br/><br/>
    //         <div className=' container'>
    //         <h1 id='tit'> ANSL Blog</h1>
    //             <div className='cards'>
    //                 <Card style={{ width: '18rem' }}>
    //                 <Card.Img variant="top" src={bg}/>
    //                 <Card.Body>
    //                     <Card.Title>Card Title</Card.Title>
    //                     <Card.Text>
    //                     Some quick example text to build on the card title and make up the
    //                     bulk of the card's content.
    //                     </Card.Text>
    //                     <Button variant="primary">Continua</Button>
    //                 </Card.Body>
    //                 </Card>
    //                 <Card style={{ width: '18rem' }}>
    //                 <Card.Img variant="top" src="holder.js/100px180" />
    //                 <Card.Body>
    //                     <Card.Title>Card Title</Card.Title>
    //                     <Card.Text>
    //                     Some quick example text to build on the card title and make up the
    //                     bulk of the card's content.
    //                     </Card.Text>
    //                     <Button variant="primary">Continua</Button>
    //                 </Card.Body>
    //                 </Card>
    //                 <Card style={{ width: '18rem' }}>
    //                 <Card.Img variant="top" src="holder.js/100px180" />
    //                 <Card.Body>
    //                     <Card.Title>Card Title</Card.Title>
    //                     <Card.Text>
    //                     Some quick example text to build on the card title and make up the
    //                     bulk of the card's content.
    //                     </Card.Text>
    //                     <Button variant="primary">Continua</Button>
    //                 </Card.Body>
    //                 </Card>
    //             </div>
    //             <div className='cards'>
    //                 <Card style={{ width: '18rem' }}>
    //                 <Card.Img variant="top" src="holder.js/100px180" />
    //                 <Card.Body>
    //                     <Card.Title>Card Title</Card.Title>
    //                     <Card.Text>
    //                     Some quick example text to build on the card title and make up the
    //                     bulk of the card's content.
    //                     </Card.Text>
    //                     <Button variant="primary">Continua</Button>
    //                 </Card.Body>
    //                 </Card>
    //                 <Card style={{ width: '18rem' }}>
    //                 <Card.Img variant="top" src="holder.js/100px180" />
    //                 <Card.Body>
    //                     <Card.Title>Card Title</Card.Title>
    //                     <Card.Text>
    //                     Some quick example text to build on the card title and make up the
    //                     bulk of the card's content.
    //                     </Card.Text>
    //                     <Button variant="primary">Continua</Button>
    //                 </Card.Body>
    //                 </Card>
    //                 <Card style={{ width: '18rem' }}>
    //                 <Card.Img variant="top" src="holder.js/100px180" />
    //                 <Card.Body>
    //                     <Card.Title>Card Title</Card.Title>
    //                     <Card.Text>
    //                     Some quick example text to build on the card title and make up the
    //                     bulk of the card's content.
    //                     </Card.Text>
    //                     <Button variant="primary">Continua</Button>
    //                 </Card.Body>
    //                 </Card>
    //             </div>
    //             <div className='cards'>
    //                 <Card style={{ width: '18rem' }}>
    //                 <Card.Img variant="top" src="holder.js/100px180" />
    //                 <Card.Body>
    //                     <Card.Title>Card Title</Card.Title>
    //                     <Card.Text>
    //                     Some quick example text to build on the card title and make up the
    //                     bulk of the card's content.
    //                     </Card.Text>
    //                     <Button variant="primary">Continua</Button>
    //                 </Card.Body>
    //                 </Card>
    //                 <Card style={{ width: '18rem' }}>
    //                 <Card.Img variant="top" src="holder.js/100px180" />
    //                 <Card.Body>
    //                     <Card.Title>Card Title</Card.Title>
    //                     <Card.Text>
    //                     Some quick example text to build on the card title and make up the
    //                     bulk of the card's content.
    //                     </Card.Text>
    //                     <Button variant="primary">Continua</Button>
    //                 </Card.Body>
    //                 </Card>
    //                 <Card style={{ width: '18rem' }}>
    //                 <Card.Img variant="top" src="holder.js/100px180" />
    //                 <Card.Body>
    //                     <Card.Title>Card Title</Card.Title>
    //                     <Card.Text>
    //                     Some quick example text to build on the card title and make up the
    //                     bulk of the card's content.
    //                     </Card.Text>
    //                     <Button variant="primary">Continua</Button>
    //                 </Card.Body>
    //                 </Card>
    //             </div>
    //         </div>
    // </div>
    // </>
  )
}

export default blog